import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io"
      }}>{`Our`}</a>{` `}<a parentName="p" {...{
        "href": "http://sb.dashbot.io"
      }}>{`SuperBot`}</a>{` conference was a great success!`}</p>
    <p>{`We had an amazing group of speakers and attendees who shared insights, learnings, and advice in the bot space.`}</p>
    <p>{`Below are videos from the event.`}</p>
    <h4>{`Keynote: Arte Merritt of Dashbot`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Ma1hgWOmcJ0?feature=oembed" width="1200"></iframe>
    <h4>{`User Acquisition and Discovery`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/OdQSi_ni7nI?feature=oembed" width="1200"></iframe>
    <p>{`Featuring:`}</p>
    <ul>
      <li parentName="ul">{`Dmitrii Dumik, CEO/Co-founder, Chatfuel`}</li>
      <li parentName="ul">{`Stefan Behren, Co-founder, Gyant`}</li>
      <li parentName="ul">{`Stefan Kojouharov, Founder, Chatbots Life`}</li>
      <li parentName="ul">{`Linda Lee, Facebook`}</li>
      <li parentName="ul">{`Khari Johnson, VentureBeat`}</li>
    </ul>
    <h4>{`Monetization`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/HsWb17RDdRM?feature=oembed" width="1200"></iframe>
    <p>{`Featuring:`}</p>
    <ul>
      <li parentName="ul">{`David Cohen, eBay`}</li>
      <li parentName="ul">{`Lauren Kunze, Co-founder, Pandorabots`}</li>
      <li parentName="ul">{`Mark Wasiljew, Fandango`}</li>
      <li parentName="ul">{`Peter Buchroithner, Co-founder, Swell`}</li>
      <li parentName="ul">{`Ellen Huet, Bloomberg`}</li>
    </ul>
    <h4>{`Big Brand Bot Strategy`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/UoH-URtoKRQ?feature=oembed" width="1200"></iframe>
    <p>{`Featuring:`}</p>
    <ul>
      <li parentName="ul">{`Andrew Pinzler, NBC Today`}</li>
      <li parentName="ul">{`Drew Lesicko, AOL`}</li>
      <li parentName="ul">{`Sergio Silva, Kik`}</li>
      <li parentName="ul">{`Ken Yeung, VentureBeat`}</li>
    </ul>
    <h4>{`The Age of Voice`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/_yRP2B4CnUk?feature=oembed" width="1200"></iframe>
    <p>{`Featuring:`}</p>
    <ul>
      <li parentName="ul">{`Anamita Guha, IBM Watson`}</li>
      <li parentName="ul">{`Kenton Langstroth, Intersection`}</li>
      <li parentName="ul">{`Max Koziolek, Spectrm`}</li>
      <li parentName="ul">{`Vera Tzoneva, Google`}</li>
      <li parentName="ul">{`Mario Tapia, MobileMonday`}</li>
    </ul>
    <h4>{`Investor Insights`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/i7B-fYl_P4U?feature=oembed" width="1200"></iframe>
    <p>{`Featuring:`}</p>
    <ul>
      <li parentName="ul">{`Alex Ferrara, Bessemer`}</li>
      <li parentName="ul">{`Courtney Nelson, BDMI`}</li>
      <li parentName="ul">{`John Rodkin, Samsung Next`}</li>
      <li parentName="ul">{`Ryan Armbrust, ffVC`}</li>
      <li parentName="ul">{`Jon Bruner, O’Reilly`}</li>
    </ul>
    <h4>{`Until next time…`}</h4>
    <p>{`Stay tuned for our next SuperBot conference in 2018.`}</p>
    <p>{`In the meantime, come to any of our `}<a parentName="p" {...{
        "href": "https://www.meetup.com/Dashbot-Bot-Meetup-San-Francisco-New-Technology/"
      }}>{`monthly meetups`}</a>{` in either SF or NYC.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, Twitter, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      